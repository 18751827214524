



















































import Vue, { VueConstructor } from 'vue'
import { ValidationObserver } from 'vee-validate'

const defaultModel = () => ({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  role: ''
})

interface ComponentWithValidator extends Vue {
  $refs: {
    validator: InstanceType<typeof ValidationObserver>
  }
}

export default (Vue as VueConstructor<ComponentWithValidator>).extend({
  props: {
    value: {
      type: Object,
      default: defaultModel
    },
    roles: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      model: {
        ...defaultModel(),
        ...this.value
      }
    }
  },

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.model = newValue
      }
    }
  },

  methods: {
    validate() {
      this.$refs.validator.validate().then((isValid: boolean) => {
        if (isValid) {
          this.$emit('submit', {
            ...this.model,
            phone: this.model.phone.replace(/\s/g, '')
          })
        }
      })
    },

    setErrors(this: any, errors: any) {
      errors.forEach((error: any) => {
        Object.keys(error.constraints).forEach((rule: string) => {
          switch (rule) {
            case 'isUnique':
              this.$refs.validator.setErrors({
                [error.property]: 'Déjà utilisé'
              })
              break
          }
        })
      })
    }
  }
})
