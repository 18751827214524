














import Vue from 'vue'
import UserForm from '@/apps/FNM/components/UserForm.vue'

import User from '@/store/models/User'
import { SFD_ROLES } from '@/constants'

export default Vue.extend({
  components: {
    UserForm
  },

  data() {
    return {
      active: false,
      creating: false,
      roles: SFD_ROLES,
      model: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        role: ''
      }
    }
  },

  mounted() {
    this.active = true
  },

  methods: {
    async onAdd(this: any, user: any) {
      this.creating = true
      try {
        await User.api().create(user)
        this.creating = false
        this.active = false
      } catch (error) {
        this.creating = false
        if (error.response && error.response.status === 400) {
          this.$refs.form.setErrors(error.response.data.message)
        }
      }
    }
  }
})
